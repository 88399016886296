import queryString from 'query-string';

import {handleLoginEvent} from '../../modules/auth-buttons';
import {NAVIGATION_ROUTES} from '../../modules/auth-pages/constants';

function handleAuthSuccessRedirect () {
  const isAuthRoute = Object.values(NAVIGATION_ROUTES).some(
    navigationRoute => window.location.pathname.includes(navigationRoute)
  );

  if (isAuthRoute) {
    const query = queryString.parse(decodeURIComponent(location.search)) || {};

    window.location.href = query.redirectURL || '/';
  }
}

function initAuthSuccessRedirect () {
  handleLoginEvent(handleAuthSuccessRedirect);
}

export {initAuthSuccessRedirect};

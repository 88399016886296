import {
  displayLoginButton,
  displayLogoutButton,
  initAuthButton
} from './modules/auth-buttons';
import {LogoutTracker} from './services/analytics/auth/logout';
import {refreshUserProperties} from './services/analytics/jitsu-helpers';
import initUserTypeDimension from './services/analytics/user';
import {checkControllerServiceWorker} from './services/helpers';
import hiveApi from './services/hive-sdk';
import {sendOnesignalUserIdToApi} from './services/onesignal-helpers';
import {
  applyRefreshToken,
  getAccessToken,
  removeAuthorizationData
} from './services/tokens';
import {initAuthSuccessRedirect} from './website-ui/helpers/auth-success-redirect';
import {getUser} from './website-ui/repositories/user-memory-repository';
import {initGoogleAuth} from './google-auth';

initAuthButton();
initAuthSuccessRedirect();
checkControllerServiceWorker();

async function initAuthorizationData (user) {
  if (user.isAuthenticated) {
    sendOnesignalUserIdToApi(hiveApi.sendPushUserId, getAccessToken());
    if (user.isFetched) {
      refreshUserProperties(user);
    } else {
      const updatedUser = await window.userMemoryRepository.update();

      refreshUserProperties(updatedUser);
    }
  }
}

const user = getUser();

if (!user.isAuthenticated) {
  applyRefreshToken(
    async () => {
      displayLogoutButton();
      const updatedUser = await window.userMemoryRepository.update();

      refreshUserProperties(updatedUser);
    },
    () => {
      initGoogleAuth();
      displayLoginButton();
    },
    () => {
      LogoutTracker.systemAction();
      removeAuthorizationData();
    });
}

initAuthorizationData(user);
initUserTypeDimension(user.isAuthenticated);

window.userMemoryRepository && window.userMemoryRepository.subscribe((_, state) => {
  initAuthorizationData(state);
  initUserTypeDimension(state.isAuthenticated);
});
